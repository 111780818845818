import { Link } from "gatsby"
import React from "react"
import Alert from 'react-bootstrap/Alert';

import Page from "../components/page"

export default function Index() {
	return (
		<>
			<Page>
				<Alert variant="danger">
					404 - this page was not found on the server.
				</Alert>
				<Link to="/">Return to homepage</Link>
			</Page>
		</>
	);
}